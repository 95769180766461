<template>
  <b-modal
    id="partner-corporate-modal"
    title="Corporate Information"
    cancel-title="CANCEL"
    ok-title="SAVE CHANGES"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Company Name"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.corporate_name.$anyError : null"
          >
            <b-form-input v-model="form.corporate_name" placeholder="Enter Company Name" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_name.required : null">
              company Name is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Address"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.corporate_address.$anyError : null"
          >
            <b-form-input v-model="form.corporate_address" placeholder="Enter Address" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_address.required : null">
              address is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <div class="mb-4">
            <b-form-group
              label="City"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.corporate_city.$anyError : null"
            >
              <b-form-input v-model="form.corporate_city" placeholder="Enter City" />
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_city.required : null">
                city is required
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="mb-4">
            <b-form-group
              label="Country"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.corporate_country.$anyError : null"
            >
              <b-form-select
                v-model="form.corporate_country"
                :options="countryList"
                :state="$v.form.$anyDirty ? !$v.form.corporate_country.$anyError : null"
                @change="onCountryChange"
              >
                <template #first>
                  <b-form-select-option :value="null">
                    Select Country
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_country.required : null">
                country is required
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Telephone"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.corporate_telphone.$anyError : null"
          >
            <b-form-input v-model="form.corporate_telphone" placeholder="Enter Telephone" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_telphone.required : null">
              telephone is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Operation Type">
            <b-form-radio-group v-model="operationType">
              <b-form-radio :value="0">
                Service Provider
              </b-form-radio>
              <b-form-radio :value="1">
                Distributor
              </b-form-radio>
              <b-form-radio :value="2">
                Both
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import countryList, { getCountryCode } from '@/common/countryList'
import { updatePartnerCorporateData } from '@/api/partner'

export default {
  name: 'CorporateModal',
  data () {
    return {
      form: {
        id: null,
        corporate_name: '',
        corporate_country: null,
        corporate_city: null,
        corporate_address: '',
        corporate_telphone: '',
        service_provide: false,
        distributor: true
      }
    }
  },
  validations () {
    return {
      form: {
        corporate_name: { required },
        corporate_country: { required },
        corporate_city: { required },
        corporate_address: { required },
        corporate_telphone: { required }
      }
    }
  },
  computed: {
    countryList () {
      return countryList
    },
    operationType: {
      get () {
        if (this.form.service_provide && this.form.distributor) return 2
        else if (this.form.service_provide) return 0
        else return 1
      },
      set (value) {
        this.form.service_provide = value === 0 || value === 2
        this.form.distributor = value === 1 || value === 2
      }
    }
  },
  methods: {
    show (data) {
      this.$bvModal.show('partner-corporate-modal')
      this.form = data
      this.$v.form.$reset()
    },
    hide () {
      this.$bvModal.hide('partner-corporate-modal')
    },
    onCountryChange (value) {
      this.form.corporate_telphone = `+${getCountryCode(value)}`
    },
    submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      updatePartnerCorporateData(this.form).then(() => {
        this.$emit('update')
        this.hide()
      })
    }
  }
}
</script>
