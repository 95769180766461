<template>
  <div>
    <div class="areawrap">
      <b-row>
        <b-col cols="5">
          <div class="d-flex align-items-end px-4">
            <h2 class="mb-0 mr-4">
              Corporate Information
            </h2>
            <b-link v-if="partnerData.status === 0" class="text-gray-500" href="#" @click.prevent="openCorporateModal">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
            </b-link>
            <span class="text-primary font-size-sm ml-auto">
              Date Added
            </span>
            <span class="font-size-base font-weight-normal ml-2">
              {{ $dayjs(partnerData.created_at).format('MMM D, YYYY') }}
            </span>
          </div>
          <hr>
          <b-form-group label="Corporate Name" label-class="text-primary mb-1">
            {{ partnerData.corporate_name }}

            <span
              v-if="partnerData.status === 0"
              class="d-inline-block bg-primary text-white rounded-pill px-2 py-0 ml-4"
            >
              Pending
            </span>
            <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0 ml-4">
              Active
            </span>
          </b-form-group>
          <b-form-group label="Address" label-class="text-primary mb-1">
            <span class="d-block">{{ partnerData.corporate_address }}</span>
            <span class="d-block">{{ partnerData.corporate_city }}, {{ partnerCountry }}</span>
          </b-form-group>
          <b-form-group label="Telephone" label-class="text-primary mb-1">
            {{ partnerData.corporate_telphone }}
          </b-form-group>
          <b-form-group v-if="!isEditOperationType" label="Operation Type" label-class="text-primary mb-1">
            <span v-if="partnerData.distributor" class="d-inline-block bg-info text-white rounded-pill px-2 py-0 mr-2">
              Distributor
            </span>
            <span
              v-if="partnerData.service_provide"
              class="d-inline-block bg-info2 text-white rounded-pill px-2 py-0 mr-2"
            >
              Service Provider
            </span>
            <b-link
              v-if="partnerData.status === 1"
              class="text-gray-500"
              href="#"
              @click.prevent="editOperationTypeHandler"
            >
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
            </b-link>
          </b-form-group>
          <template v-else>
            <b-form-group label="Operation Type">
              <b-form-radio-group v-model="operationType">
                <b-form-radio :value="0" :disabled="typeEditPermission===2 || typeEditPermission===0">
                  Service Provider
                </b-form-radio>
                <b-form-radio :value="1" :disabled="typeEditPermission===1 || typeEditPermission===0">
                  Distributor
                </b-form-radio>
                <b-form-radio :value="2">
                  Both
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>

            <b-button variant="primary" class="btn-width px-8" @click="submitOperationType">
              SAVE CHANGES
            </b-button>
          </template>
        </b-col>

        <b-col>
          <div class="d-flex align-items-end px-4">
            <h2 class="mb-0 mr-4">
              Owner Information
            </h2>
            <b-link v-if="partnerData.status === 0" class="text-gray-500" href="#" @click.prevent="openOwnerModal">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
            </b-link>
          </div>
          <hr>
          <b-form-group label="Owner" label-class="text-primary mb-1">
            {{ partnerData.owner_name || '-' }}
          </b-form-group>
          <b-form-group label="Email" label-class="text-primary mb-1">
            {{ partnerData.owner_email || '-' }}
          </b-form-group>
          <b-form-group label="Telephone" label-class="text-primary mb-1">
            {{ partnerData.owner_extension || '-' }}
          </b-form-group>
        </b-col>

        <b-col>
          <h2 class="px-4">
            Contact
          </h2>
          <hr>
          <b-form-group label="Contact" label-class="text-primary mb-1">
            {{ partnerData.contact_name || '-' }}
          </b-form-group>
          <b-form-group label="Email" label-class="text-primary mb-1">
            {{ partnerData.contact_email || '-' }}
          </b-form-group>
          <b-form-group label="Telephone" label-class="text-primary mb-1">
            {{ partnerData.contact_extension || '-' }}
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div class="areawrap">
      <b-row class="align-items-center mb-4">
        <b-col cols="auto">
          <div v-if="partnerData.distributor && partnerData.service_provide" class="tabs-base">
            <b-tabs v-model="tabIndex" content-class="mt-3" @input="changeTabHandler">
              <b-tab title="Partner List" active />
              <b-tab title="Clinic List" />
            </b-tabs>
          </div>
          <h2 v-else-if="partnerData.service_provide" class="mb-0">
            Clinic List
          </h2>
          <h2 v-else class="mb-0">
            Partner List
          </h2>
        </b-col>

        <b-col cols="4" class="ml-auto">
          <div class="position-relative">
            <b-form-input v-model="search" placeholder="Search" class="pr-12" @keyup.enter="searchName = search" />
            <font-awesome-icon
              :icon="['fas', 'search']"
              fixed-width
              class="input-search text-primary"
              @click="searchName = search"
            />
          </div>
        </b-col>
        <b-col cols="2">
          <b-form-select v-model="country" :options="countryList">
            <template #first>
              <b-form-select-option :value="null">
                Select Country
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>

      <div class="table-base">
        <b-table hover borderless :items="sortCurrentList" :fields="fields" @row-clicked="onRowSelected">
          <template #head(name)>
            <div class="cursor-pointer" @click="sortList('name')">
              NAME
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>
          <template #head(status)>
            <div class="cursor-pointer" @click="sortList('status')">
              STATUS
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>
          <template #head(location)>
            <div class="cursor-pointer" @click="sortList('location')">
              LOCATION
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>
          <template #head(partner)>
            <div class="cursor-pointer" @click="sortList('partner')">
              PARTNERS
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>
          <template #head(clinic)>
            <div class="cursor-pointer" @click="sortList('clinic')">
              CLINICS
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>
          <template #head(type)>
            <div class="cursor-pointer" @click="sortList('type')">
              OPERATION TYPE
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>
          <template #head(contact)>
            <div class="cursor-pointer" @click="sortList('contact')">
              CONTACT
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>
          <template #head(email)>
            <div class="cursor-pointer" @click="sortList('email')">
              EMAIL
              <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
            </div>
          </template>

          <template #cell(name)="data">
            {{ data.item.name }}
          </template>
          <template #cell(status)="data">
            <span v-if="data.item.status === 0" class="d-inline-block bg-primary text-white rounded-pill px-2 py-0">
              Pending
            </span>
            <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0">
              Active
            </span>
          </template>
          <template #cell(type)="data">
            <span v-if="data.item.distributor" class="d-inline-block bg-info text-white rounded-pill px-2 py-0 mr-2">
              Distributor
            </span>
            <span v-if="data.item.service_provide" class="d-inline-block bg-info2 text-white rounded-pill px-2 py-0">
              Service Provider
            </span>
          </template>
        </b-table>
      </div>
    </div>

    <corporate-modal ref="corporateModal" @update="init" />
    <owner-modal ref="ownerModal" @update="init" />
  </div>
</template>

<script>
import countryList from '@/common/countryList'
import { getPartnerData, getPartnerSpecifyList, updatePartnerCorporateData } from '@/api/partner'
import { getClinicSpecifyList } from '@/api/clinic'
import corporateModal from '@/components/partner/corporateModal'
import ownerModal from '@/components/partner/ownerModal'

export default {
  name: 'PartnerDetail',
  components: {
    corporateModal,
    ownerModal
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      partnerData: {},
      tabIndex: 0,
      isEditOperationType: false,
      operationTypeData: {
        service_provide: false,
        distributor: true
      },

      search: '',

      searchName: '',
      country: null,
      field: 'name',
      ascending: true,

      partnerList: [],
      clinicList: []
    }
  },
  computed: {
    countryList () {
      return countryList
    },
    partnerCountry () {
      return countryList.find(item => item.value === this.partnerData.corporate_country)?.text
    },
    listType () {
      return 0
    },
    typeEditPermission () {
      // BOTH的話 不能再改
      if (this.partnerData.service_provide && this.partnerData.distributor) { return 0 }
      // 如果是 service provider 可以改成 Both but not distributor
      if (this.partnerData.service_provide && !this.partnerData.distributor) { return 1 }
      // 如果是 Distributor 可以改成 Both but not service provide
      if (this.partnerData.distributor && !this.partnerData.service_provide) { return 2 }
      return 0
    },
    fields () {
      if (this.showPartnerList) {
        return ['name', 'status', 'location', 'partner', 'clinic', 'type']
      } else {
        return ['name', 'status', 'location', 'contact', 'email']
      }
    },
    filterPartnerList () {
      return this.partnerList.reduce((list, partner) => {
        // console.log(JSON.stringify(partner))

        if (this.searchName && partner.corporate_name.indexOf(this.searchName) === -1) return list
        if (this.country && partner.corporate_country !== this.country) return list

        let typeCount = 0
        if (partner.service_provide) typeCount += 1
        if (partner.distributor) typeCount += 1
        const country = this.countryList.find(city => city.value === partner.corporate_country)?.text
        list.push({
          id: partner.id,
          name: partner.corporate_name,
          status: partner.status,
          location: `${partner.corporate_city}, ${country}`,
          partner: partner.partner_count,
          clinic: partner.clinic_count,
          type: typeCount,
          service_provide: partner.service_provide,
          distributor: partner.distributor
        })
        return list
      }, [])
    },
    filterClinicList () {
      return this.clinicList.reduce((list, clinic) => {
        if (this.searchName && clinic.clinic_name.indexOf(this.searchName) === -1) return list
        if (this.country && clinic.clinic_country !== this.country) return list

        const country = this.countryList.find(city => city.value === clinic.clinic_country)?.text
        list.push({
          id: clinic.id,
          name: clinic.clinic_name,
          status: clinic.status,
          location: `${clinic.clinic_city}, ${country}`,
          contact: clinic.contact_name,
          email: clinic.contact_email
        })
        return list
      }, [])
    },
    sortCurrentList () {
      const list = this.showPartnerList ? this.filterPartnerList : this.filterClinicList
      return list.slice().sort((a, b) => {
        const dataA = String(a[this.field]).toLowerCase()
        const dataB = String(b[this.field]).toLowerCase()
        if (dataA > dataB) return this.ascending ? 1 : -1
        if (dataA < dataB) return this.ascending ? -1 : 1
        else return 0
      })
    },

    operationType: {
      get () {
        if (this.operationTypeData.service_provide && this.operationTypeData.distributor) return 2 // both
        else if (this.operationTypeData.service_provide) return 0 // service_provide only
        else return 1 // distributor only
      }
      // set (value) {
      //   this.operationTypeData.service_provide = value === 0 || value === 2
      //   this.operationTypeData.distributor = value === 1 || value === 2
      // }
    },
    showPartnerList () {
      return this.tabIndex === 0 && this.operationType !== 0
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      await this.getPartnerData()
      if (this.partnerData.distributor) {
        this.getPartnerSpecifyList()
      } else {
        this.getClinicSpecifyList()
      }
      this.$store.commit('SET_BREADCRUMB', [
        {
          text: 'Partner List',
          to: { name: 'PartnerList' }
        },
        {
          text: this.partnerData.corporate_name
        }
      ])
    },
    changeTabHandler () {
      this.initParams()
      if (this.showPartnerList) {
        this.getPartnerSpecifyList()
      } else {
        this.getClinicSpecifyList()
      }
    },
    initParams () {
      this.search = ''
      this.searchName = ''
      this.country = null
      this.field = 'name'
      this.ascending = true
    },
    getPartnerData () {
      return getPartnerData(this.id).then(res => {
        this.partnerData = res.data.data.partner
        this.operationTypeData.distributor = this.partnerData.distributor
        this.operationTypeData.service_provide = this.partnerData.service_provide
      })
    },
    getPartnerSpecifyList () {
      getPartnerSpecifyList(this.id).then(res => {
        this.partnerList = res.data.data
      })
    },
    getClinicSpecifyList () {
      getClinicSpecifyList(this.id).then(res => {
        this.clinicList = res.data.data
      })
    },
    sortList (field) {
      if (this.field === field) {
        this.ascending = !this.ascending
      } else {
        this.field = field
        this.ascending = true
      }
    },
    onRowSelected (item) {
      if (!this.showPartnerList) {
        this.$store.commit('SET_BREADCRUMB', [
          {
            text: 'Partner List',
            to: { name: 'PartnerList' }
          },
          {
            text: this.partnerData.corporate_name,
            to: {
              name: 'PartnerDetail',
              params: { id: this.id }
            }
          },
          { text: item.name }
        ])
        this.$router.push({ name: 'ClinicDetail', params: { id: item.id, partnerId: this.id } })
      }
    },
    openCorporateModal () {
      const data = {
        id: this.id,
        corporate_name: this.partnerData.corporate_name,
        corporate_country: this.partnerData.corporate_country,
        corporate_city: this.partnerData.corporate_city,
        corporate_address: this.partnerData.corporate_address,
        corporate_telphone: this.partnerData.corporate_telphone,
        service_provide: this.partnerData.service_provide,
        distributor: this.partnerData.distributor
      }
      this.$refs.corporateModal.show(data)
    },
    openOwnerModal () {
      const data = {
        id: this.id,
        owner_name: this.partnerData.owner_name,
        owner_email: this.partnerData.owner_email,
        owner_extension: this.partnerData.owner_extension
      }
      this.$refs.ownerModal.show(data)
    },

    editOperationTypeHandler () {
      this.isEditOperationType = true
      this.operationTypeData.service_provide = this.partnerData.service_provide
      this.operationTypeData.distributor = this.partnerData.distributor
    },
    submitOperationType () {
      const data = Object.assign({}, this.partnerData, this.operationTypeData)
      data.id = this.id
      updatePartnerCorporateData(data).then(() => {
        this.isEditOperationType = false
        this.init()
      })
    }
  }
}
</script>
